import type { Config } from "tailwindcss";

export const mintGreen = "#15bfa3";
export const purple = "#784dd6";
export const red = "#f45a5a";
export const skyBlue = "#88d8f1";
export const yellow = "#f6ce3f";
export const grays = [
  "#F2F2F2",
  "#E6E6E6",
  "#D9D9D9",
  "#cdcdcd",
  "#b2b2b2",
  "#999999",
  "#8b8b8b",
  "#666666",
  "#5c5557",
  "#333333",
] as const;

const config: Config = {
  content: ["./src/**/*.{js,ts,jsx,tsx,mdx}"],
  theme: {
    extend: {
      colors: {
        "uw-green": mintGreen,
        "uw-purple": purple,
        "uw-red": red,
        "uw-blue": skyBlue,
        "uw-yellow": yellow,
        ...Object.fromEntries(
          grays.map((color, index) => [`uw-gray-${index}`, color]),
        ),
      },
      fontSize: {
        xs: "0.766rem",
      },
    },
  },
  plugins: [],
};

export default config;
